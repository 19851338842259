
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ImportModal from "@/components/modals/forms/importModal.vue";
import TGNotification, {
  addNotification,
  exportData,
  TGNotifications,
  viewNotification,
} from "@/core/data/notifications";
import { getNotification, deleteNotification } from "@/core/data/notifications";
import { csvJSON } from "@/core/helpers/utils";
import store from "@/store";

export default defineComponent({
  name: "Notification-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    ImportModal,
  },
  setup() {
    const checkedItems = ref([]);
    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const search = ref<string>("");
    const isLoading = ref<boolean>(true);

    let selectedItem = ref({});
    const tableData = ref<Array<TGNotification>>(TGNotifications);
    const initItems = ref<Array<TGNotification>>([]);
    const getListItems = function (pageNumber) {
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getNotification(currentPage.value, search.value).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          initItems.value.splice(0, resObj.data.length, ...resObj.data);
          res(resObj);
        });
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Items Listing", ["Apps", "Items"]);
      getListItems(1);
    });
    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };
    const deleteFewItems = () => {
      checkedItems.value.forEach((item) => {
        deleteNotification(item);
      });
      checkedItems.value.length = 0;
    };
    const deleteItemItem = (item) => {
      if (confirm("Are you sure want to delete?")) {
        deleteNotification(item).then(() => {
          getListItems(currentPage.value);
        });
      }
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };
    const importNotification = (importData) => {
      let csvData = csvJSON(importData);
      return new Promise((res, rej) => {
        if (csvData) {
          csvData = JSON.parse(csvData);
          console.log(csvData);
          let arrReqList: any = [];
          for (let i = 0; i < csvData.length; i++) {
            let itemObj: any = csvData[i];
            delete itemObj.id;
            arrReqList.push(addNotification(itemObj, ""));
          }
          // Promise.all(arrReqList).then((data)=>{
          //   res(true);
          // });
        }
      });
    };
    const exportNotification = () => {
      return exportData();
    };
    const viewNotificationItem = (item) => {
      viewNotification(item);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const hasCreate = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasEdit = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasDelete = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: "Title",
          key: "title",
          sortable: false,
        },
        {
          name: "Message",
          key: "message",
          sortable: false,
        },
      ];
      columnsArr.push({
        name: "Actions",
        key: "actions",
        sortable: false,
      });
      return columnsArr;
    });
    return {
      setSelectedItem,
      selectedItem,
      total,
      tableData,
      tableHeader,
      deleteNotification,
      getNotification,
      getListItems,
      deleteItemItem,
      search,
      searchItems,
      checkedItems,
      deleteFewItems,
      onPageChange,
      importNotification,
      exportNotification,
      isLoading,
      currentPage,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      viewNotificationItem,
    };
  },
});
