import router from "@/router";
import axios from "axios";
import ApiService from "../services/ApiService";
interface TGNotification {
  id: number;
  title: string;
  message: string;
  module_name: string;
  updated_data_content: any;
  previous_data_content: any;
}
const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const TGNotifications: Array<TGNotification> = [];

const jsonToInterface = function (item: any) {
  const newItem: TGNotification = {
    id: 0,
    title: "",
    message: "",
    module_name: "",
    updated_data_content: {},
    previous_data_content: {},
  };
  newItem.id = item.id;
  newItem.title = item.title;
  newItem.message = item.message;
  newItem.module_name = item.module_name;
  newItem.previous_data_content = JSON.parse(
    item.previous_data_content || "{}"
  );
  newItem.updated_data_content = JSON.parse(item.updated_data_content || "{}");
  return newItem;
};
const deleteNotification = function (item: TGNotification) {
  return ApiService.delete(`notification/${item.id}/`);
};

const addNotification = function (item, id) {
  if (id) {
    return ApiService.put(`notification/${id}/`, item);
  } else {
    return ApiService.post("notification/", item);
  }
};
const exportData = function () {
  return new Promise((res, rej) => {
    ApiService.csvFile("notification/export/", "notification-data").then(
      (data) => res(data)
    );
  });
};
const getNotification = function (pageNumber = 1, title = "") {
  return new Promise((res, rej) => {
    res({
      count: 0,
      data: [],
    })
    return
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: title,
    };
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`notification/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};
const getNotificationAll = function () {
  return new Promise((res, rej) => {
    ApiService.get(`notification/all`)
      .then(function (response) {
        const resObj: any = {
          data: [],
        };
        if (response.data && response.data) {
          resObj.data = response.data.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const viewNotification = function (item: TGNotification) {
  console.log(item);
  const routeObj: any = {};
  switch (item.module_name) {
    case "UNFREEZE_PURCHASE_REQUEST":
    case "PURCHASE_REQUEST":
      routeObj.name = "apps-edit-purchase-request";
      routeObj.params = {
        id: item.updated_data_content.id || item.previous_data_content.id,
      };
      break;
    case "STOCK_REQUEST":
      routeObj.name = "apps-edit-stock-request";
      routeObj.params = {
        id: item.updated_data_content.id || item.previous_data_content.id,
      };
      break;
    case "QUOTATION":
      routeObj.name = "apps-edit-purchase-request";
      routeObj.params = {
        id:
          item.updated_data_content.purchase_request ||
          item.previous_data_content.pr_id,
      };
      routeObj.query = {
        quotationId:
          item.updated_data_content.id || item.previous_data_content.id,
      };
      break;

    default:
      break;
  }
  if (routeObj.name) {
    router.push(routeObj);
  }
};
export {
  TGNotifications,
  getNotification,
  deleteNotification,
  addNotification,
  exportData,
  getNotificationAll,
  viewNotification,
};

export default TGNotification;
