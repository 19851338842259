
import { defineComponent, reactive, toRefs, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "import-modal",
  components: {},
  props: ["onSubmit"],
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({});

    const rules = ref({});
    const file = ref<any>(null);
    const handleFileUpload = async () => {
      // debugger;
      console.log("selected file", file.value?.files);
      //Upload to server
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid && file.value?.files) {
          let f = file.value?.files[0];
          let reader = new FileReader();

          // Closure to capture the file information.
          reader.onload = (function (theFile) {
            return function (e: any) {
              if (props.onSubmit) {
                props.onSubmit(e.target.result).then(() => {
                  loading.value = false;

                  Swal.fire({
                    text: "Form has been successfully submitted!",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                });
              }
            };
          })(f);

          // Read in the image file as a data URL.
          reader.readAsText(f);
          loading.value = true;
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      file,
      handleFileUpload,
    };
  },
});
